/** @format */

import React from 'react';
import gsap from 'gsap';

const PATHS = {
	OPEN: "M0,0.5L15,0.5L30,0.5 		M0,10.5L15,10.5L30,10.5 	M0,20.5L15,20.5L30,20.5",
	CLOSE: "M5.5,0.5L15.5,10.5L25.5,0.5 M15,10.5L15,10.5L15,10.5 	M5.5,20.5L15.5,10.5L25.5,20.5"
};

export default class NavToggle extends React.Component {
	constructor(props) {
		super(props);
		this.pathRef = React.createRef();
	}

	componentDidUpdate() {
		const path = (this.props.menuOpen) ? PATHS.CLOSE : PATHS.OPEN;
		gsap.to(this.pathRef.current, {attr: {d: path}, duration: 0.5, ease: "power4.inOut"});
	}

	render() { 
		return (
		<svg className="navToggle" width="30" height="21" viewBox="0 0 30 21" onClick={this.props.onClick}>
			<path
			className='menu-open'
			d="M0,0.5L15,0.5L30,0.5 M0,10.5L15,10.5L30,10.5 M0,20.5L15,20.5L30,20.5" 
			ref={ this.pathRef }
			/>
		</svg>
		)
	}
}
