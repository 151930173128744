/** @format */

import React from 'react';
import { render } from 'react-dom';
import MainRouter from './components/Router';
import gsap from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { SplitText } from "gsap/SplitText";

import './css/guardian.css';
import './css/benton.css';
import './css/style.css';
import './scss/main.scss';

// https://greensock.com/docs/v3/Plugins/ScrollTrigger
gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(SplitText);
gsap.defaults({ease: "power4.inOut", duration: 1});

render(<MainRouter />, document.querySelector('#main'));
