/** @format */
import React from 'react';

class Terms extends React.Component {
	
	state = {
	}
	
	componentDidMount() {
	}

	componentWillUnmount() {
	}

	render() {
		return (
			<div id="sectionTerms">
				<h1>Terms</h1>
			</div>
		);
	}
}

export default Terms;
