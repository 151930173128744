import React from 'react';
import { withRouter } from 'react-router-dom';
import { VisibilityHelper } from '../../utils/VisibilityHelper';
import ArtTitle from "./ArtTitle";

class ArtPreview extends React.Component {
	mainRef = React.createRef();
	state = { visible: false };

	showAnimation = () => {
		this.setState({visible: true});
		this.viz.destroy();
	}

	componentDidMount() {
		this.viz = new VisibilityHelper(this.mainRef.current, this.showAnimation);
	}

	componentWillUnmount() {
		this.viz.destroy();
	}

	viewPiece = () => {
		this.props.history.push(`/artworks/`+ this.props.details.genericName);
	}

	getShadow(itemID) {
		switch (itemID) {
			case 11:
			case 12:
			case 16:
			case 19:
				return "noShadow";
			break;
			default: 
				return "";
			break;
		}
	}

	render() {
		const d = this.props.details;
		const vizClass = this.state.visible ? "visible" : "";
		const roundedCorners = d.title.indexOf("Platinum") > -1 ? "rounded" : "";
		const shadowClass = this.getShadow(d.genericName);

		return (
			<div className={`artPreview ${vizClass}`} ref={ this.mainRef } onClick={ this.viewPiece }>
				<div className={`imgCrop ${roundedCorners} ${shadowClass}`}>
					<img className="imgInner" src={d.artworkImage} alt={ `The artwork ${d.title}` } />
				</div>
				<ArtTitle text={ d.title } visible={ this.state.visible }></ArtTitle>
				<div className="padded">
					<h2 className="txtArtist">by {d.artist}</h2>
					<p className="feature-link small">VIEW PIECE ⟶</p>
					<hr />
				</div>
			</div>
		);
	}
}

// React is so weird...
// This gives us access to this.props.history.push()
export default withRouter(ArtPreview);
