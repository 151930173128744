/** @format */
import React from 'react';
import gsap from "gsap";
import SplitText from "gsap/SplitText";
import Eye from "./svgs/Eye";
import artworks from '../artworks';


// Images to preload
const IMAGES = [
	"/images/home-bg.png",
	"/images/map.jpg",
	"/images/mapThumbnail.jpg",
	"/images/program-bg.png",
	"/images/bg-drawer.png",
	"/images/bg-footer.png",
];

export default class Preloader extends React.Component {
	constructor(props) {
		super(props);
		this.count = 0;
		this.state = { percent: 0, loadDone: false };
		this.selfRef = React.createRef();
		this.introRef = React.createRef();
		this.svgRef = React.createRef();
		this.coverRef = React.createRef();

		// Add art images to preload set
		artworks.forEach(art => {
			IMAGES.push(art.artworkImage);
		})
	}

	// 1. Start preloading all images
	componentDidMount() {
		let img;
		IMAGES.forEach((src, i) => {
			img = new Image();
			img.onload = () => this.preloadProgress();
			img.onerror = () => this.preloadProgress(src);
			img.src = src;
		});

		// No intro text
		if (this.props.skipIntro) {
			this.introRef.current.style.display = "none";
			return;
		}

		// Animate intro text
		this.splits = new SplitText(this.introRef.current, {
			type: "lines",
			linesClass: "line line++"
		});
		gsap.to(this.splits.lines, {
			maskImage: "linear-gradient(#fff 100%, transparent 100%)",
			y: "0em",
			opacity: 1,
			duration: 2,
			stagger: 0.2,
			ease: "power2.out"
		});
	}

	// 2. Count up on each load or error
	preloadProgress = (url) => {
		// Log error, but continue progress
		if (url !== undefined) {
			console.warn(`Error loading image at "${url}"`);
		}

		this.count ++;
		const percent = Math.round(this.count / IMAGES.length * 100);
		this.setState({ percent: percent });

		gsap.killTweensOf(this.svgRef.current);
		gsap.to(this.svgRef.current, {
			maskImage: `linear-gradient(110deg, #fff ${percent}%, #ffffff40 ${percent}%)`,
			duration: 1,
			onComplete: this.loadComplete
		});
	}

	// 3. Loading is finished
	loadComplete = () => {
		if (this.state.percent === 100) {
			if (this.props.skipIntro) {
				this.exitAnimation();
			} else {
				this.setState({ loadDone: true });
			}
		}
	}

	// 4. Click EXPLORE button
	onButtonClick = () => {
		// Skip intro from here on out
		localStorage.setItem("skipIntro", true);
		this.exitAnimation();
	}

	// 5. Exit animation
	exitAnimation() {
		gsap.to(this.coverRef.current, {opacity: 1, duration: 1, ease: "none", onComplete: () => {
			gsap.to(this.selfRef.current, {opacity: 0, duration: 1, delay: 0.5, display: "none", ease: "none"});
			this.props.loadComplete();
		}});
	}

	render() {
		const message = this.state.percent < 100 ? "Loading Experience ..." : "Welcome!";
		const completeClass = this.state.loadDone ? "complete" : "";
		return (
			<div id="preloader" className={ completeClass } ref={ this.selfRef }>
				<h3 className="introText" ref={ this.introRef }>
					A collection by emerging artists<br/>
					and designers curated exclusively<br/>
					for Platinum Card® Members.<br/>
					Learn more or purchase a piece<br/>
					by scanning the QR codes<br/>
					throughout the space.
				</h3>
				<div className="lowerBox">
					<div className="loadInfo">
						<div className="svgOuter" ref={ this.svgRef }>
							<Eye></Eye>
						</div>
						<h4 className="italic">{ message }</h4>
						<h4 className="italic">{this.state.percent}%</h4>
					</div>
					<div className="linkButton" onClick={ this.onButtonClick }>CONTINUE</div>
				</div>
				<div className="fullCover" ref={ this.coverRef }></div>
			</div>
		);
	}
}
