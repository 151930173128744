/** @format */
import React from 'react';
import QrReader from 'react-qr-reader'
import ScannerDrawer from './ScannerDrawer'

class Scanner extends React.Component {
	
	state = {
		result: 'Scan QR codes to learn more',
		error: 'Camera access required for full experience',
		rotationError: 'Pleae rotate device to enable scanner',
		scanlink: '',
		isScanning: true,
		screenDirection: 'portrait',
	}
	
	detectOrientation = () => {
		const screenDir = window.orientation;
		
		if (screenDir == 0){
			this.setState({
				screenDirection: 'portrait',
			});
		}
		else {
			this.setState({
				screenDirection: 'landscape',
			});
		}
	}

	componentDidMount() {
		const aresult = this.state.result;
		const camCanvas = document.querySelector('.thinking-qr-code');
		camCanvas.insertAdjacentHTML('beforeend', '<h4 id="noresult" className="italic">' + aresult + '</h4>');
		window.addEventListener('orientationchange', this.detectOrientation);
	}

	componentWillUnmount() {
	}

	
	handleScan = data => {
		if (data) {
			this.setState({
				result: data,
				/* hasResult: true, */
			});
			const results = document.querySelector('#result');
			const drawer = document.querySelector('.drawer');
			const reticle = document.querySelector('.thinking-qr-code section div');
			
			if (results == null) {
				reticle.classList.add('hidden');

				setTimeout(function(){
					drawer.classList.add('open');
				}, 500);
				setTimeout(function(){
					const empty = document.querySelector('.drawerImageEmpty');
					if (empty) {
						drawer.classList.remove('open');
						reticle.classList.remove('hidden');
						const noResult = document.querySelector('#noresult');
						if (noResult == null) {
							const camCanvas = document.querySelector('.thinking-qr-code');
							camCanvas.insertAdjacentHTML('beforeend', '<h4 id="noresult" class="italic">Scan QR codes to learn more</h4>');
						}
					}
					
				}, 500);
			}
			const noresults = document.querySelector('#noresult');
			if (noresults !== null) {
				noresults.remove();
			}
		}
	}
	  
	handleError = err => {
		if (err.toString().toLowerCase().includes('permission')) {
			const camCanvas = document.querySelector('.thinking-qr-code');
			const noResult = document.querySelector('#noresult');
			noResult.remove();
			camCanvas.insertAdjacentHTML('beforeend', '<h4 id="error" class="italic">' + this.state.error + '</h4>');
		}
	}

	onLoad = obj => {
		console.log(obj);
	}

	render() {
		const previewStyle = {
			height: 240,
			width: 320,
			opacity: 0,
		  }
		return (
			<React.Fragment>	
				{ this.state.screenDirection === 'portrait' && 
					(<div id="sectionScan">
						<QrReader
						delay={300}
						onLoad={this.onLoad}
						onError={this.handleError}
						onScan={this.handleScan}
						style={{ previewStyle }}
						className={'thinking-qr-code'}>
						</QrReader>
						<ScannerDrawer scandata={this.state.result}></ScannerDrawer>
					</div>)}
					{ this.state.screenDirection === 'landscape' && 
					(<div id="sectionScan">
						<h4 id="error" class="italic">{this.state.rotationError}</h4>
					</div>)}
			</React.Fragment>
		);
	}
}

export default Scanner;
