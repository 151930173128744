/** @format */
import React from 'react';
import { Link } from 'react-router-dom';
import artworks from '../artworks';
import Eye from './svgs/Eye';
import ArrowDown from './svgs/ArrowDown';
import Footer from './Footer';
import ArtPreviewCollection from './art/ArtPreviewCollection';
import ExploreTheSpace from './ExploreTheSpace';
import gsap from "gsap";

class Home extends React.Component {
	constructor (props) {
		super(props);
		this.collectionRef = React.createRef();
		this.scanBoxRef = React.createRef();
		this.state = {
			artworks: artworks,
		}
	}
	
	componentDidMount() {
		window.scrollTo(0, 0);
		if (this.props.loadComplete) this.introAnimation();
	}

	componentDidUpdate() {
		if (this.props.loadComplete) {
			this.introAnimation();
		}
	}

	// Play intro animation if preloading done
	introAnimation() {
		const q = gsap.utils.selector(this.scanBoxRef.current);
		const corners = q(".corner");
		gsap.fromTo(corners, { scale: 0.5, opacity: 0, svgOrigin: "50 50" }, {
			scale: 1.0,
			opacity: 1.0,
			duration: 2,
			stagger: 0.2,
		});
		gsap.to(q(".scanTxt"), { y: "0px", duration: 1.5, opacity: 1, stagger: 0.5, delay: 0.5, ease: "power2.out" });
		gsap.to(q(".svgEye"), { duration: 1, opacity: 1, delay: 2, ease: "power2.out" });
	}
	
	onReticleClick = () => {
		this.props.history.push('/scanner');
	}
	
	scrollToMap = () => {
		const posY = this.collectionRef.current.offsetTop + 50;
		gsap.to(window, 1, {scrollTo:{ y:posY }, ease: 'power2.inOut'});
	}

	render() {
		return (
		<div id="sectionHome">
			{/* Landing */}
			<div className="homeLanding">
				<video className="homeVideo" playsInline loop autoPlay>
					<source src="./videos/homeVidTemp.mp4"></source>
				</video>
				<div className="homeReticle" onClick={ this.onReticleClick } ref={ this.scanBoxRef }>
					<svg className="corners" viewBox="0 0 100 100">
						<path className="corner" transform-origin="50 50" d="M1,20 v-19 h19"></path>
						<path className="corner" transform-origin="50 50" d="M1,80 v19 h19"></path>
						<path className="corner" transform-origin="50 50" d="M99,20 v-19 h-19"></path>
						<path className="corner" transform-origin="50 50" d="M99,80 v19 h-19"></path>
					</svg>
					<h3 className="scanTxt">Scan QR codes to</h3>
					<h3 className="scanTxt">explore the space</h3>
					<Eye></Eye>
				</div>
				<div className="exploreLink" onClick={this.scrollToMap}>
					<p>Explore the Studio</p>
					<ArrowDown></ArrowDown>
				</div>
			</div>


			{/* Collection */}
			<div className="homeCollection" ref={ this.collectionRef }>
				<div className="bgWhite"></div>
				<div className="bgWave"></div>
				<div className="homeInner">
					<h5>FEATURED ITEMS</h5>
					<h6>THE COLLECTION</h6>
					<ArtPreviewCollection artworks={this.state.artworks} featuredOnly={true}></ArtPreviewCollection>
					<Link to="/explore" className="linkButton">View All</Link>
				</div>

				<ExploreTheSpace></ExploreTheSpace>
			</div>

			{/* Explore */}
			<Footer></Footer>
		</div>
		);
	}
}

export default Home;