/** @format */
import React from 'react';
import Footer from './Footer';

class About extends React.Component {
	
	state = {
	}
	
	componentDidMount() {
		window.scrollTo(0, 0);
	}

	componentWillUnmount() {
	}

	render() {
		return (
			<React.Fragment>
			<div>
				<div id="sectionAbout">
					<section>
						<h1>Art x Platinum </h1>
						<p>The Platinum Card® has always been a canvas for
						our Members to create and reflect their unique
						lives. It’s why we offer a wide range of benefits
						that help you experience the world differently.
						And now, we’re proud to introduce Art x Platinum
						– artistic collaborations to continue the tradition
						of inspiring your everyday.</p>
						<p>We’ve partnered with two renowned artists
						to launch Art x Platinum and bring you two
						reimagined metal designs to choose from in
						addition to the sleek classic Platinum design. The
						result: new designs – one with the botanical
						brilliance of Kehinde Wiley and the other with the
						abstract modernity that epitomizes Julie Mehretu
						– that transform and redefine what the Platinum
						Card can be.</p>
						<p>Available January 20, 2022</p>
						<img class="abtimg" src="/images/artworks/23.png" />
						<img class="abtimg" src="/images/artworks/24.png" />
						<div className="linkButton" ><a href="https://americanexpress.com/artbyplatinum/" target="_blank">Learn More</a></div>
					</section>
				</div>
				
			</div>
			<Footer></Footer>
			</React.Fragment>
		);
	}
}

export default About;
