/** @format */
import React from 'react';
import artworks from '../artworks';
import Drawer from './Drawer';

class ScannerDrawer extends React.Component {
	
    state = {
		scandata: '',
        artworks: [],
        pageData: [],
        genericId: '',
	}

	componentDidMount() {
        this.setState({
            artworks: artworks,
            genericId: this.props.scandata.split('/')[4],
        });
        const found = this.state.artworks.find(element => element.genericName === this.state.genericId );
        this.setState ({pageData : [found]});
	}

    componentWillReceiveProps(nextProps) {
        this.setState({
            genericId: this.props.scandata.split('/')[4],
        });
        const found = this.state.artworks.find(element => element.genericName.toString() === this.state.genericId );
        this.setState ({pageData : [found]});
      }

	componentWillUnmount() {
	}

	render() {
        return (
			    <React.Fragment>
                    <div className={`drawer`}>
                           <Drawer pagedata={this.state.pageData}></Drawer>
                    </div>
                </React.Fragment>
		    );
        }
    }

export default ScannerDrawer;
