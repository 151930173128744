/** @format */
import React from 'react';
import Eye from "../svgs/Eye";

class Hotspot extends React.Component {
	constructor(props){
		super(props);
		this.state = {
			visited: false,
			isActive: false
		}
	}


	componentDidUpdate() {
		// If we have an ID match, this hotspot is active
		const isActive = (this.props.active === (this.props.detail.genericName - 1));
		if (isActive !== this.state.isActive) {
			this.setState({isActive: isActive});
		}

		// Set visited state upon first visit
		if (isActive && this.state.visited === false) {
			this.setState({visited: true});
		}
	}

	render() {
		const d = this.props.detail;
		const pos = { left: `${d.hotspot.posX}px`, top: `${d.hotspot.posY}px`};
		const activeClass = this.state.isActive ? "active" : "";
		const visitedClass = this.state.visited ? "visited" : "";

		return (
			<div className={`hotspot ${activeClass} ${visitedClass}`} style={ pos } onClick={ () => this.props.onClick()}>
				<div className="circle white"></div>
				<div className="circle grey"></div>
				<Eye></Eye>
				<div className="tooltip">
					<div className="caret"></div>
					<p className="small toolText">{d.title}</p>
				</div>
			</div>
		);
	}
}

export default Hotspot;
