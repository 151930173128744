// Helper class that tells us when HTMLElement is visible or hidden
export class VisibilityHelper {
	constructor(htmlElem, visibleCallback, hiddenCallback) {
		this.target = htmlElem;
		this.observer = new IntersectionObserver((entities) => {
			const ratio = entities[0].intersectionRatio;

			// Elem is hidden
			if (ratio <= 0.0 && hiddenCallback) {
				hiddenCallback();
			}
			// Elem is visible
			else if (ratio >= 0.5 && visibleCallback) {
				visibleCallback();
			}
		}, {threshold: [0.0, 0.5]});

		this.observer.observe(this.target);
	}

	destroy() {
		if(this.observer) this.observer.unobserve(this.target);
		this.observer = null;
	}
}
