/** @format */
import React from 'react';
import { Link } from 'react-router-dom';
import NavToggle from './svgs/NavToggle';
import NavLogo from './svgs/NavLogo'
import NavScanner from './svgs/NavScanner';
import gsap from 'gsap';

const LINKS = [
	{dest: "/scanner", text: "Scan"},
	{dest: "/explore", text: "Explore"},
	{dest: "/map", text: "Map"},
	{dest: "/programming", text: "Programming"},
	{dest: "/art-x-platinum", text: "Art x Platinum"},
]; 

const PATHS = {
	OPEN: "M0,0 v100 C25 100, 25 125, 50 125 v-125 z",
	CLOSED: "M0,0 v0 C25 0, 25 -15, 50 -15 v15 z"
}

class Nav extends React.Component {
	constructor(props) {
		super(props);
		this.state = { menuOpen: false, atTop: false, menuBusy: false }
		this.pathRef = React.createRef();
		this.linkRefs = [];
}	

	toggleMenu = () => {
		if (this.state.menuBusy) return;
		this.setState({ menuBusy: true });

		this.state.menuOpen ? this.closeMenu() : this.openMenu();
	}

	componentDidMount() {
		window.addEventListener("scroll", this.onScroll);
		this.onScroll();
	}

	comeponentWillUnmount() {
		window.removeEventListener("scroll", this.onScroll);
	}

	onScroll = (evt) => {
		const transparent = this.state.atTop;

		if (document.documentElement.scrollTop < 50 && !transparent) {
			this.setState({atTop: true});
		} else if(document.documentElement.scrollTop >= 50 && transparent) {
			this.setState({atTop: false});
		}
	}
	
	openMenu = () => {
		document.querySelector('body').classList.add('locked');
		this.setState({menuOpen: true});
		gsap.to(this.pathRef.current, {attr: {d: PATHS.OPEN}, duration: 1, ease: "power2.inOut", onComplete: () => {
			this.setState({ menuBusy: false });
		}});

		this.linkRefs.forEach((elem, i) => {
			gsap.to(elem.current, {y: "0%", duration: 0.5, ease: "power2.out", delay: 0.5 + i * 0.07});
		});
	}
	  
	closeMenu = () => {
		document.querySelector('body').classList.remove('locked');
		this.setState({menuOpen: false});
		gsap.to(this.pathRef.current, {attr: {d: PATHS.CLOSED}, duration: 1, ease: "power2.in", onComplete: () => {
			this.setState({ menuBusy: false });
		}});

		this.linkRefs.forEach((elem, i) => {
			gsap.to(elem.current, {y: "100%", duration: 0.3, ease: "power2.in", delay: (5 - i) * 0.05});
		});
	}

	renderLinks() {
		const loc = this.props.location.pathname;

		return LINKS.map((link, i) => {
			this.linkRefs[i] = React.createRef();
			const className = (loc === link.dest) ? "navLink active" : "navLink";

			return <Link className={className} onClick={this.closeMenu} to={link.dest} key={`navLink-${i}`} >
				<h3 ref={ this.linkRefs[i] }>{ link.text }</h3>
			</Link>
		});
	}
	
	render() {
		const isHome = this.props.location.pathname === "/";
		// Nav inverts colors when at top of home, or when menu open
		const navInvert = (this.state.atTop && isHome) || this.state.menuOpen ? "invert" : "";
		const menuOpen = this.state.menuOpen ? "open" : "";

		return (
			<React.Fragment>
			{/* MAIN MENU */}
			<div id="menu" className={ menuOpen }>
				<div id="menuBack">
					<svg viewBox="0 0 45 125">
						<path d={ PATHS.CLOSED } ref={ this.pathRef }></path>
					</svg>
				</div>
				<div id="menuLinks">
					{ this.renderLinks() }
					<p className="small copy">&copy; American Express</p>
				</div>
			</div>

			{/* TOP NAVIGATION */}
			<div id="navbar" className={ navInvert }>
				<NavToggle onClick={this.toggleMenu} menuOpen={this.state.menuOpen}></NavToggle>
				<NavLogo linkTo="/" onClick={this.closeMenu}></NavLogo>
				<NavScanner linkTo="/scanner" onClick={this.closeMenu}></NavScanner>
			</div>
			</React.Fragment>
		);
	}
}

export default Nav;
