/** @format */

import React from 'react';
import { Link } from 'react-router-dom';

const NavScanner = (props) => (
<Link to={props.linkTo}  onClick={props.onClick}>
	<svg className="navScanner" width="46" height="35" viewBox="0 0 46 39" fill="none" xmlns="http://www.w3.org/2000/svg">
	  	<path className='eye' d="M17.4,19.4c8.6,0,15.9-7.9,17.4-9.7C33.3,8,26,0,17.4,0S1.5,8,0,9.7C1.5,11.5,8.8,19.4,17.4,19.4Z" transform="translate(6, 10)"/>
		<circle className='eye' cx="23.5" cy="19.5" r="8.5"/>
		<path className='crosshairs' d="M1.5,9.5 v-9 h9 M45.5,9.5 v-9 h-9 M1.5,29.5v9h9 M45.5,29.5 v9 h-9" />
	</svg>
</Link>
);


export default NavScanner;
