const events =
[{
    /* DAY 2 - Thu Dec 2 */
    "events": 
    [{
        "time": "9:00-10:00am",
        "location": "The Miami Beach EDITION",
        "headline": "American Express \nPlatinum x Equinox - Immersive Pilates",
        "about": "American Express Platinum Card Members are invited to complimentary group fitness classes led by Equinox’s renowned instructors. Advanced reservations required."
    },
    {
        "time": "12:00-5:00pm",
        "location": "The Miami Beach EDITION",
        "headline": "American Express \nPlatinum Studio",
        "about": "A masterfully curated oasis at The Miami Beach EDITION for American Express Platinum Card Members. Platinum Studio brings to life the benefits of Platinum Membership and supports the emerging artist community."
    },
    {
        "time": "12:00-5:00pm",
        "location": "The Miami Beach EDITION",
        "headline": "American Express \nPlatinum x Artsy",
        "about": "Discover and collect artworks from Artsy’s exclusive curated collection: Trove, only at Platinum Studio. This special edition of Trove features fresh, for-sale works by prominent emerging artists."
    },
    {
        "time": "12:00-5:00pm",
        "location": "The Miami Beach EDITION",
        "headline": "American Express \nPlatinum x Stephen Palladino",
        "about": "Artist Stephen Palladino captures your true essence in a custom, real-time portrait drawing you can take home."
    },
    {
        "time": "5:30-6:30pm",
        "location": "The Miami Beach EDITION",
        "headline": "American Express \nPlatinum x Equinox - Yin Yoga followed by a Sound Bath",
        "about": "American Express Platinum Card Members are invited to complimentary group fitness classes led by Equinox’s renowned instructors. Advanced reservations required."
    }]

},
{
    /* DAY 3 - Fri Dec 3 */
    "events": 
    [{
        "time": "9:00-10:00am",
        "location": "The Miami Beach EDITION",
        "headline": "American Express \nPlatinum x Equinox - Vinyasa Flow",
        "about": "American Express Platinum Card Members are invited to complimentary group fitness classes led by Equinox’s renowned instructors. Advanced reservations required."
    },
    {
        "time": "12:00-5:00pm",
        "location": "The Miami Beach EDITION",
        "headline": "American Express \nPlatinum Studio",
        "about": "A masterfully curated oasis at The Miami Beach EDITION for American Express Platinum Card Members. Platinum Studio brings to life the benefits of Platinum Membership and supports the emerging artist community."
    },
    {
        "time": "12:00-5:00pm",
        "location": "The Miami Beach EDITION",
        "headline": "American Express \nPlatinum x Artsy",
        "about": "Discover and collect artworks from Artsy’s exclusive curated collection: Trove, only at Platinum Studio. This special edition of Trove features fresh, for-sale works by prominent emerging artists."
    },
    {
        "time": "12:00-5:00pm",
        "location": "The Miami Beach EDITION",
        "headline": "American Express \nPlatinum x Stephen Palladino",
        "about": "Artist Stephen Palladino captures your true essence in a custom, real-time portrait drawing you can take home."
    },
    {
        "time": "7:00-9:30pm",
        "location": "La Vista Terrace \nat The Miami Beach EDITION",
        "headline": "American Express \nPlatinum x Carbone",
        "about": "American Express Platinum Card Members are invited to experience an exclusive dinner with Global Dining Access Chef Mario Carbone. Reservations are required and bookable through Resy."
    }]

},
{
    /* DAY 4 - Sat Dec 4  */
    "events": 
    [{
        "time": "10:00-3:00pm",
        "location": "The Miami Beach EDITION",
        "headline": "American Express \nPlatinum Studio",
        "about": "A masterfully curated oasis at The Miami Beach EDITION for American Express Platinum Card Members. Platinum Studio brings to life the benefits of Platinum Membership and supports the emerging artist community."
    },
    {
        "time": "10:00-3:00pm",
        "location": "The Miami Beach EDITION",
        "headline": "American Express \nPlatinum x Artsy",
        "about": "Discover and collect artworks from Artsy’s exclusive curated collection: Trove, only at Platinum Studio. This special edition of Trove features fresh, for-sale works by prominent emerging artists."
    },
    {
        "time": "10:00-3:00pm",
        "location": "The Miami Beach EDITION",
        "headline": "American Express \nPlatinum x Stephen Palladino ",
        "about": "Artist Stephen Palladino captures your true essence in a custom, real-time portrait drawing you can take home."
    },
    {
        "time": "5:30-8:00pm",
        "location": "La Vista Terrace at \nThe Miami Beach EDITION",
        "headline": "American Express \nPlatinum x Carbone",
        "about": "American Express Platinum Card Members are invited to experience an exclusive dinner with Global Dining Access Chef Mario Carbone. Reservations are required and bookable through Resy."
    },
    {
        "time": "8:00-10:15pm",
        "location": "The Miami Beach EDITION",
        "headline": "American Express \nUNSTAGED with Lizzo",
        "about": "A concert experience with three-time, GRAMMY® Award-winning, multi-platinum superstar Lizzo, performing live for American Express Platinum Card Members. Tickets are required."
    }]

},
{
    /* DAY 5 - Sun Dec 5  */
    "events": 
    [{
        "time": "12:00 - 14:00",
        "location": "Some Place",
        "headline": "Headline 17",
        "about": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Amet sed libero id elementum molestie nec odio. Congue elit ut vel volutpat diam orci. Tempus facilisis justo turpis hac sed risus lacus feugiat porttitor."
    },
    {
        "time": "16:00 - 18:00",
        "location": "Some Place",
        "headline": "Headline 18",
        "about": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Amet sed libero id elementum molestie nec odio. Congue elit ut vel volutpat diam orci. Tempus facilisis justo turpis hac sed risus lacus feugiat porttitor."
    },
    {
        "time": "18:00 - 20:00",
        "location": "Some Place",
        "headline": "Headline 19",
        "about": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Amet sed libero id elementum molestie nec odio. Congue elit ut vel volutpat diam orci. Tempus facilisis justo turpis hac sed risus lacus feugiat porttitor."
    },
    {
        "time": "20:00 - 20:00",
        "location": "Some Place",
        "headline": "Headline 20",
        "about": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Amet sed libero id elementum molestie nec odio. Congue elit ut vel volutpat diam orci. Tempus facilisis justo turpis hac sed risus lacus feugiat porttitor."
    }]

},
{
    /* DAY 6 - Mon Dec 6  */
    "events": 
    [{
        "time": "12:00 - 14:00",
        "location": "Some Place",
        "headline": "Headline 21",
        "about": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Amet sed libero id elementum molestie nec odio. Congue elit ut vel volutpat diam orci. Tempus facilisis justo turpis hac sed risus lacus feugiat porttitor."
    },
    {
        "time": "16:00 - 18:00",
        "location": "Some Place",
        "headline": "Headline 22",
        "about": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Amet sed libero id elementum molestie nec odio. Congue elit ut vel volutpat diam orci. Tempus facilisis justo turpis hac sed risus lacus feugiat porttitor."
    },
    {
        "time": "18:00 - 20:00",
        "location": "Some Place",
        "headline": "Headline 23",
        "about": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Amet sed libero id elementum molestie nec odio. Congue elit ut vel volutpat diam orci. Tempus facilisis justo turpis hac sed risus lacus feugiat porttitor."
    },
    {
        "time": "20:00 - 20:00",
        "location": "Some Place",
        "headline": "Headline 24",
        "about": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Amet sed libero id elementum molestie nec odio. Congue elit ut vel volutpat diam orci. Tempus facilisis justo turpis hac sed risus lacus feugiat porttitor."
    }]

},
{
    /* DAY 7 - Tue Dec 7  */
    "events": 
    [{
        "time": "12:00 - 14:00",
        "location": "Some Place",
        "headline": "Headline 25",
        "about": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Amet sed libero id elementum molestie nec odio. Congue elit ut vel volutpat diam orci. Tempus facilisis justo turpis hac sed risus lacus feugiat porttitor."
    },
    {
        "time": "16:00 - 18:00",
        "location": "Some Place",
        "headline": "Headline 26",
        "about": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Amet sed libero id elementum molestie nec odio. Congue elit ut vel volutpat diam orci. Tempus facilisis justo turpis hac sed risus lacus feugiat porttitor."
    },
    {
        "time": "18:00 - 20:00",
        "location": "Some Place",
        "headline": "Headline 27",
        "about": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Amet sed libero id elementum molestie nec odio. Congue elit ut vel volutpat diam orci. Tempus facilisis justo turpis hac sed risus lacus feugiat porttitor."
    },
    {
        "time": "20:00 - 20:00",
        "location": "Some Place",
        "headline": "Headline 28",
        "about": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Amet sed libero id elementum molestie nec odio. Congue elit ut vel volutpat diam orci. Tempus facilisis justo turpis hac sed risus lacus feugiat porttitor."
    }]

}]

export default events;