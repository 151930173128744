/** @format */
import React from 'react';
import { Link } from 'react-router-dom';
import { VisibilityHelper } from '../utils/VisibilityHelper';
import gsap from 'gsap';

class Footer extends React.Component {
	state = {hotspotActive: false};
	mainRef = React.createRef();

	showAnimation = () => {
		const q = gsap.utils.selector(this.mainRef);
		gsap.to(q(".mapImageInner"), { y: "0%", ease: "power4.out" });
		gsap.to(q(".title"), { y: "0%", opacity: 1, ease: "power4.out" });
		this.setState({hotspotActive: true})

		this.viz.destroy();
	}

	componentDidMount() {
		this.viz = new VisibilityHelper(this.mainRef.current, this.showAnimation);
	}

	componentWillUnmount() {
		this.viz.destroy();
	}

	render() {
		return (
			<div className="exploreSpace" ref={ this.mainRef }>
				<div className="mapImageOuter">
					<div className="mapImageInner"></div>
					<div className="fakeHotspot"></div>
				</div>
				<div className="exploreMapDesc">
					<h1 className="title">Explore the space</h1>
					<p>Discover fine art, designer furniture and one-of-a-kind pieces of art.</p>
					<Link to="/map" className="linkButton">See the Map</Link>
				</div>
			</div>
		);
	}
}

export default Footer;
