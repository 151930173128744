/** @format */
import React from 'react';
import AmexPlatinum from './svgs/AmexPlatinum';
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

class Footer extends React.Component {
	waveRef = React.createRef();
	scrolly = null;

	componentDidMount() {
		ScrollTrigger.clearScrollMemory();
		const anim = gsap.to(this.waveRef.current, {
			y: "-115vw",
			ease: "none",
		});
		this.scrolly = ScrollTrigger.create({
			animation: anim,
			trigger: this.waveRef.current,
			scrub: 1,
			start: "top bottom",
			end: "top 25%"
		});
		gsap.delayedCall(0.5, () => {
			this.scrolly.refresh();
		});
	}

	componentWillUnmount() {
		this.scrolly.kill();
	}

	onClickArtsy = () => {
		window.open("https://www.artsy.net/", "_new");
	}
	onClickDesign = () => {
		window.open("https://shop.designmiami.com/", "_new");
	}

	render() {
		return (
			<div id="footer">
				<AmexPlatinum></AmexPlatinum>
				<hr />
				<h4 className="footer">Platinum Studio is an experience brought to you by American Express along with our curatorial partners, Artsy and Surface Area. Our aim is to bring the best of art and design to our Card Members in a one-of-a-kind gallery on the beach in Miami.</h4>
				<div className="sponsors">
					<img className="sponsor1" src="/images/footer/logoArtsy.png" onClick={this.onClickArtsy} alt="ArtSy logo"/>
					<img className="sponsor2" src="/images/footer/logoSurface.png" onClick={this.onClickDesign} alt="Design Miami logo"/>
				</div>
				<p className="small">&copy;2021 American&nbsp;Express. All rights reserved.
				<br /><a href="https://www.americanexpress.com/us/company/privacy-center/online-privacy-disclosures/" target="_blank">Privacy Policy</a>
				</p>
				
				<div className="waveCover" ref={ this.waveRef }>
					<svg className="waveCoverSVG" viewBox="0 0 5 7">
						<path d="M0,5.9 C2.0 5.9, 2.5 7, 5 7 L5,0 L0,0 Z"></path>
					</svg>
				</div>
			</div>
		);
	}
}

export default Footer;
