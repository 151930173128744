/** @format */
import React from 'react';

class MultiHotspot extends React.Component {
	constructor(props){
		super(props);
		this.state = {
			visited: false,
			isActive: false
		}
	}

	componentDidUpdate() {
		let isActive = false;
		// If we have an ID match, this hotspot is active
		this.props.artArray.forEach((art) => {
			if (this.props.active === (art.genericName - 1)) {
				isActive = true;
				return;
			}
		});

		if (isActive !== this.state.isActive) {
			this.setState({isActive: isActive});
		}

		// Set visited state upon first visit
		if (isActive && this.state.visited === false) {
			this.setState({visited: true});
		}
	}

	onCircleClick = () => {
		// Set no artwork to active to close
		if (this.state.isActive) {
			this.props.onClick(-1);
		}
		// Set first artwork to active to open
		else {
			this.onListClick(0);
		}
	}

	onListClick = (artIndex) => {
		const art = this.props.artArray[artIndex];
		// genericName acts as an index, but starting from 1
		const artID = art.genericName - 1;
		this.props.onClick(artID);
	}

	renderArts() {
		// Arrows: ➔ → ⟶
		return this.props.artArray.map((art, index) => {
			const selectedClass = (this.props.active === art.genericName - 1) ? "selected" : "";
			return <p className={`small listItem ${selectedClass}`} key={`list-${index}`} onClick={ () => this.onListClick(index) }>
				<span className="title">{ art.title }</span>
				<span className="arrow">➔</span>
			</p>
		});
	}

	render() {
		const d = this.props.artArray;
		const pos = { left: `${d[0].hotspot.posX}px`, top: `${d[0].hotspot.posY}px`};
		const activeClass = this.state.isActive ? "active" : "";

		return (
			<div className={`hotspot multiple ${activeClass}`} style={ pos }>
				<div className="circle grey"></div>
				<p className="quantity">{ d.length }</p>
				<svg className="close" viewBox="0 0 20 20" onClick={ this.onCircleClick }>
					<path d="M1,1 L19,19 M1,19 L19,1"/>
				</svg>
				<div className="tooltip">
					<div className="caret"></div>
					<div className="listBox">{ this.renderArts() }</div>
				</div>
			</div>
		);
	}
}

export default MultiHotspot;

