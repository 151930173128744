/** @format */
import React from 'react';
import Footer from './Footer';
import events from '../events';

class Programming extends React.Component {
	dateRef = React.createRef();

	state = {
		events: [],
		todaysevents: [],
		eventnumber: 0,
	}

	setDayofweek = () => {
		document.querySelector('.events-data').classList.add('fade-out-fade-in');
		document.querySelector('.weekday').classList.add('fade-out-fade-in');
		const days = ['Thu','Fri','Sat'];
		const index = this.dateRef.current.selectedIndex;
		document.querySelector('.weekday').innerHTML = days[index];
		this.setState({ eventnumber: index });
		this.setState({ todaysevents: [events[index]]});
		setTimeout(function(){ 
			document.querySelector('.events-data').classList.remove('fade-out-fade-in');
			document.querySelector('.weekday').classList.remove('fade-out-fade-in'); 
		}, 1500);	
	};

	
	componentDidMount() {
		window.scrollTo(0, 0);
		this.setState({ events: events });
		this.setState({ todaysevents: [events[this.state.eventnumber]]})
	}

	componentWillUnmount() {
	}

	render() {
		return (
				<React.Fragment>
				<div>
					<div className="program-header">
					</div>
				</div>
				<div id="sectionProgram">
					<h5>PROGRAMMING</h5>
					<h6>WHATS HAPPENING</h6>
					<div className="date-selector">
					<p>Select day</p>
						<div>
							<select name="dates" id="dates" ref={this.dateRef} className="date-select" onChange={this.setDayofweek}>
								<option defaultValue="1202">12/2</option>
								<option value="1203">12/3</option>
								<option value="1204">12/4</option>
							</select>
						</div>
						<div  style={{textAlign:'center'}}>
							<div className="weekday" style={{margin:'0 auto',width:'100%'}}>Thu</div>
						</div>
					</div>

					{this.state.todaysevents.map((i, key) => {
                        return (
							<div key={key}>
								{i.events  && (
										<div>
											<div key={key} className="event-header bb"></div>
											<div className="events-data">
												{
												i.events.map((j, key) => {
													return (
														<div key={key} className="bb">
															<div className="event-body">
																<h2 className="event">{j.headline}</h2>
																<p><strong>Location:</strong> {j.location}</p>
																<p><strong>Time:</strong> {j.time}</p>
																<p className="pt-10">{j.about}</p>
															</div>
														</div>
														);
													})
												}
											</div>
										</div>
									)}
							</div>
							);
                     	})}
				</div>
				<Footer></Footer>
				</React.Fragment>
		);
	}
}

export default Programming;
