/** @format */
import React from 'react';
import { Link } from 'react-router-dom';
import { titleBreak } from "../utils";

class Drawer extends React.Component {

	componentDidMount() {
	}

	componentWillUnmount() {
	}

    closeDrawer = () => {
        const drawer = document.querySelector('.drawer');
        drawer.classList.remove('open');
        const reticle = document.querySelector('.thinking-qr-code section div');
        reticle.classList.remove('hidden');
        const camCanvas = document.querySelector('.thinking-qr-code');
        const noResult = document.querySelector('#noresult');
        if (noResult !== null) {
            noResult.remove();
        }
        camCanvas.insertAdjacentHTML('beforeend', '<div id="noresult">Scan QR codes to learn more</div>');
    }
    
    render() {
        return (
			    <React.Fragment>
                     {this.props.pagedata.map((item, index) => {
                         return (
                             <div key={index}>
                                <div>
                                    <div className="drawerBgnd"></div>
                                    { item === undefined && (
                                    <div>
                                        <svg className="drawerCloseSVG" viewBox="0 0 50 50" onClick={() => this.closeDrawer()}>
                                            <path d="M18,18 L32,32"></path>
                                            <path d="M32,18 L18,32"></path>
                                        </svg>
                                        <div className="drawerGrid">
                                        </div>
                                        <div className="drawerImageEmpty"></div>
                                        <div className="drawerNav">
                                            <p className="tiny moreBtn">Learn More</p>
                                        </div>
                                    </div>
                                    )}
                                    { item !== undefined && (
                                    <div>
                                        <svg className="drawerCloseSVG" viewBox="0 0 50 50" onClick={() => this.closeDrawer()}>
                                            <path d="M18,18 L32,32"></path>
                                            <path d="M32,18 L18,32"></path>
                                        </svg>
                                        <div className="drawerGrid">
                                            <h2 className="text-prewrap thin italic">{ titleBreak(item.title) }</h2>
                                            <h5>{ item.artist }</h5>
                                            <div className="dataPoint">
                                                <h4>Price:</h4>
                                                <h5>{ item.price }</h5>
                                            </div>
                                            <div className="dataPoint">
                                                <h4>Dimensions:</h4>
                                                <h5>{ item.dimensions }</h5>
                                            </div>
                                        </div>
                                        <div className="drawerImage" style={{backgroundImage: `url("${item.artworkImage}")`}}></div>
                                        <div className="drawerNav">
                                            <Link className="moreBtn" to={`/artworks/`+ item.genericName}>
                                                <p className="tiny">Learn More</p>
                                            </Link>
                                        </div>
                                    </div>
                                    )}
                                </div>
                            </div> 
                        );
                    })}
                </React.Fragment>
		    );
	    }
    }

export default Drawer;
