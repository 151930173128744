// Fixes JavaScript mod bug
export function mod( n, m ) {
	return ( ( n % m ) + m ) % m;
}

// Replaces '-' with line-breaks for those tricky title alignments
export function titleBreak(text) {
	const regex = /-/ig;
	return text.replace(regex, ' ');
}

// Removes excess decimal precision to optimize SVG paths
function decimateSVG(path, decimals) {
	if (decimals === undefined) decimals = 0;

	return path.replace(/[0-9]*\.[0-9]*/g, function(x) {
		return (+x).toFixed(decimals);
		// return Math.round(+x);
		// return Math.round(+x * 10) / 10;
	});
}

// Expose to use in console
window.decimate = decimateSVG;
