/** @format */

import React from 'react';

const Eye = (props) => (
<React.Fragment>
<svg className="svgEye" width="37" height="22" viewBox="0 0 37 24" xmlns="http://www.w3.org/2000/svg">
  	<path d="M17.4,19.4c8.6,0,15.9-7.9,17.4-9.7C33.3,8,26,0,17.4,0S1.5,8,0,9.7C1.5,11.5,8.8,19.4,17.4,19.4Z" transform="translate(1, 2)"/>
	<circle cx="18.5" cy="11.5" r="9"/>
</svg>
</React.Fragment>
);

export default Eye;
