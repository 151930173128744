/** @format */
import React from 'react';
import ArtPreviewCollection from './art/ArtPreviewCollection';
import Footer from './Footer';
import artworks from '../artworks';

class Explore extends React.Component {
	state = {
		artworks: artworks
	};

	componentDidMount() {
		window.scrollTo(0, 0);
	}
	render() {
		return (
		<React.Fragment>
			<div id="sectionExplore">
				<h5>EXPLORE</h5>
				<h6>THE COLLECTION</h6>
				<ArtPreviewCollection artworks={this.state.artworks}></ArtPreviewCollection>
			</div>
			<Footer></Footer>
		</React.Fragment>
		);
	}
}

export default Explore;
