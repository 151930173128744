/** @format */
import React from 'react';

class Legal extends React.Component {
	
	state = {
	}
	
	componentDidMount() {
	}

	componentWillUnmount() {
	}

	render() {
		return (
			<div id="sectionLegal">
				<h1>Legal</h1>
			</div>
		);
	}
}

export default Legal;
