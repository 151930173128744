import React from 'react';
import gsap from "gsap";
import SplitText from "gsap/SplitText";

export default class ArtTitle extends React.Component {
	textRef = React.createRef();

	componentDidMount() {
		this.splits = new SplitText(this.textRef.current, {
			type: "lines",
			linesClass: "line line++",
		});

		if(this.props.visible) {
			this.componentDidUpdate();
		}
	}

	componentDidUpdate() {
		if(this.props.visible) {
			gsap.to(this.splits.lines, {
				maskImage: "linear-gradient(#fff 100%, transparent 101%)",
				y: "0%",
				opacity: 1,
				duration: 1,
				delay: 0.5,
				stagger: 0.2,
				ease: "power2.out"
			});
		}
	}

	render() {
		return (
			<h1 className="artTitle" ref={ this.textRef }>
				{this.props.text}
			</h1>
		)
	}
}