/** @format */
import React from 'react';
import Hotspot from "./Hotspot";
import MultiHotspot from "./MultiHotspot";
import ARTWORK_DATA from '../../artworks';
import { mod, titleBreak } from "../../utils";
import gsap from "gsap";

// Intro overlay will only display on first visit
let firstVisit = true;

class Map extends React.Component {
	constructor (props) {
		super(props);
		this.mapOuter = React.createRef();
		this.introOverlay = React.createRef();
		this.state = {
			activeHotspot: -1,
			prevHotspot: 0
		}
	}

	onMouseMove = (evt) => {
		// console.log(evt.layerX, evt.layerY);
	}
	
	componentDidMount() {
		// Center map
		this.mapOuter.current.scrollTo(600, 300);
	}

	activateHotspot = (index) => {
		// Disables active spot when re-clicking it
		let newIndex = (this.state.activeHotspot === index) ? -1 : index;

		this.setState({
			prevHotspot: this.state.activeHotspot,
			activeHotspot: newIndex
		});

		if (newIndex >= 0) {
			const posX = Math.max(0, ARTWORK_DATA[newIndex].hotspot.posX - window.innerWidth / 2);
			const posY = Math.max(0, ARTWORK_DATA[newIndex].hotspot.posY - window.innerHeight / 2);
			gsap.to(this.mapOuter.current, {
				scrollTo: {x: posX, y: posY},
				duration: 1,
				ease: "power2.inOut"
			});
		}
	}

	nextHotspot = (direction) => {
		let newIndex = mod(this.state.activeHotspot + direction, ARTWORK_DATA.length);
		this.activateHotspot(newIndex);
	}

	// Go to this artwork's detail page
	learnMoreClick = () => {
		const activeID = ARTWORK_DATA[this.state.activeHotspot].genericName;
		this.props.history.push(`/artworks/${activeID}`);
	}

	hideIntroOverlay = () => {
		gsap.to(this.introOverlay.current, {opacity: "0", duration: 1, display: "none", ease: "power2.inOut", onComplete: () => {
			firstVisit = false;
		}});
	}

	// Hotspots for individual artworks
	renderHotspots() {
		return ARTWORK_DATA.map((artwork, index) =>{
			// Skip if it's part of a MultiHotspot
			if (artwork.hotspot.multi !== false) return(null);

			return <Hotspot
				detail={ artwork }
				active={ this.state.activeHotspot }
				onClick={() => this.activateHotspot(index)}
				key={`hotspot-${index}`}
				></Hotspot>
		});
	}

	// Hotspots for galleries with multiple artworks
	renderMultiHotspots() {
		// First we gather matching hotspots into the same array
		const multiArray = [];
		ARTWORK_DATA.forEach((artwork, index) => {
			const multiID = artwork.hotspot.multi;
			if (multiID === false) return;

			// Create new array at multiID
			if (multiArray[multiID] === undefined) {
				multiArray[multiID] = [artwork];
			}
			// Push to existing array at multiID
			else {
				multiArray[multiID].push(artwork);
			}
		});

		return multiArray.map((artworks, index) => {
			return <MultiHotspot
				artArray={ artworks }
				active={ this.state.activeHotspot }
				onClick={ (clickedID) => this.activateHotspot(clickedID) }
				key={ `multiHotspot-${index}` }
			></MultiHotspot>
		});
	}

	render() {
		const art = this.state.activeHotspot >= 0 ? ARTWORK_DATA[this.state.activeHotspot] : ARTWORK_DATA[this.state.prevHotspot];
		const artImage = {backgroundImage: `url("${art.artworkImage}")`};

		// Classes
		const cDrawerOpen = this.state.activeHotspot >= 0 ? "open" : "";
		const cOverlayHidden = !firstVisit ? "hidden" : "";

		return (
			<div id="sectionMap">
				{/*Main Map*/}
				<div className="mapOuter" ref={ this.mapOuter }>
					<div className="mapInner"></div>
					{ this.renderHotspots() }
					{ this.renderMultiHotspots() }
				</div>

				{/*DRAWER*/}
				<div className={`drawer ${cDrawerOpen}`}>
					<div className="drawerBgnd"></div>
					<svg className="drawerCloseSVG" viewBox="0 0 50 50" onClick={() => this.activateHotspot(-1)}>
						<path d="M18,18 L32,32"></path>
						<path d="M32,18 L18,32"></path>
					</svg>
					<div className="drawerGrid">
						<h2 className="text-prewrap thin italic">{ titleBreak(art.title) }</h2>
						<h5>{ art.artist }</h5>
						<div className="dataPoint">
							<h4>Price:</h4>
							<h5>{ art.price }</h5>
						</div>
						<div className="dataPoint">
							<h4>Dimensions:</h4>
							<h5>{ art.dimensions }</h5>
						</div>
					</div>
					<div className="drawerImage" style={ artImage }></div>

					{/*Bottom Nav*/}
					<div className="drawerNav">
						<svg viewBox="0 0 50 50" className="arrow" onClick={() => this.nextHotspot(-1)}>
							<path d="M30,15 L20,25 L30,35 M20,25 L50,25"></path>
						</svg>
						<p className="tiny moreBtn" onClick={this.learnMoreClick}>Learn More</p>
						<svg viewBox="0 0 50 50" className="arrow" onClick={() => this.nextHotspot(+1)}>
							<path d="M20,15 L30,25 L20,35 M30,25 L0,25"></path>
						</svg>
					</div>
				</div>

				{/*Intro overlay*/}
				<div className={`introOverlay ${cOverlayHidden}`} ref={ this.introOverlay }>
					<h1>Explore the space</h1>
					<p>Browse through all the art pieces on our interactive map to see where they're located.</p>
					<div className="linkButton white" onClick={ this.hideIntroOverlay }>See the Map</div>
				</div>
			</div>
		);
	}
}

export default Map;
