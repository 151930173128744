/** @format */

import React from 'react';

const AmexPlatinum = (props) => (

<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 152 43">
    <path id="Platinum" d="M0,41.6l2.8-.5V21.3L0,20.9v-1H8.3c5.8,0,7.8,2.4,7.8,6.3s-3,6.4-6.9,6.4a12,12,0,0,1-2.5-.3v-1H8.9c3.2,0,4.9-1.7,4.9-5.1s-1.4-5-5.2-5H5V41.1l3.3.5v1H0Z M17.3,41.6l2.9-.5V21.3l-2.9-.4v-1h8.2v1l-3.1.4V41.2h8.7l1.1-5.1h1.2L33,42.6H17.3Z M40.6,33.9h7.5L44.4,22.8h-.1Zm-6.3,7.7,2.1-.5,7.4-21.2h1.8l7.2,21.2,2.1.5v1H47.8v-1l2.6-.5-1.9-5.9H40.1l-2,5.9,2.7.5v1H34.3Z M58.7,41.6l3-.5V21.3H55.9L55,25.8H53.9l.3-5.9H71.4l.3,5.9H70.5l-.8-4.5H63.9V41.1l3,.5v1H58.7Z M72.8,41.6l2.9-.5V21.3l-2.9-.4v-1h7.9v1l-2.8.4V41.1l2.8.5v1H72.8Z M82.2,41.6l2.9-.5V21.4l-2.9-.5v-1h5.9l11,18.7h.1V21.4l-2.9-.5v-1h7.1v1l-2.6.5V42.6H99L86.8,21.8h-.1V41.1l3,.5v1H82.2Z M107.2,35.6V21.3l-2.7-.4v-1h7.8v1l-2.9.4v14c0,4.2,2.2,6.2,6.1,6.2s5.9-1.9,5.9-6.2V21.4l-2.9-.5v-1h7v1l-2.5.4V35.4c0,4.8-2.7,7.6-7.8,7.6S107.2,40.2,107.2,35.6Z M126.5,41.6l2.8-.5V21.3l-2.8-.4v-1h6.1l6.5,18h0l6.6-18H152v1l-3,.4V41.1l3,.5v1h-7.8v-1l2.6-.5V21.6h0l-7.3,19.8h-1.3l-7.1-19.8H131V41.1l2.7.5v1h-7.2Z" />
    <path id="Amex" d="M46.4,0V10.3h2.7V0Z M41,4.2H38.4v-2H41a1,1,0,0,1,1.1,1A1,1,0,0,1,41,4.2Zm3.7-1.1A3.1,3.1,0,0,0,41.3,0H35.8V9.7h2.6V6.3h.9l2.9,3.4h3.1L42.2,6.2A3,3,0,0,0,44.7,3.1Z M52.4,4.9h0a2.2,2.2,0,0,1,2.4-2.5h2.8V0h-3c-3.1,0-4.7,2.1-4.7,4.8V5c0,3,1.8,4.7,4.5,4.7h.9l1-2.2H54.8C53.3,7.5,52.4,6.6,52.4,4.9Z M4.4,5.6,5.8,2.4,7.1,5.6ZM4.2,0,0,9.7H2.8l.8-2H7.9l.9,2h2.8L7.4,0Z M18.2,6.1,16,0H12.1V9.7h2.5v-7l2.5,7h2.2l2.5-7v7h2.5V0h-4Z M26.2,9.7h8V7.5H28.7V6h5.4V3.7H28.7V2.2h5.5V0h-8Z M60.9,5.6l1.3-3.2,1.4,3.2ZM60.7,0,56.5,9.7h2.8l.8-2h4.3l.8,2h2.9L63.9,0Z M75.6,5.9,71.7,0H68.6V9.7h2.5V3.6L75,9.7h3.1V0H75.6Z M139.7,3.7h-2.3a.8.8,0,0,1-.9-.7.8.8,0,0,1,.9-.8h4.2l.9-2.2h-5.1c-2.2,0-3.4,1.3-3.4,3.1A2.8,2.8,0,0,0,137,6h2.3a.8.8,0,0,1,.9.7.8.8,0,0,1-.9.8h-5V9.7h5c2.2,0,3.4-1.3,3.4-3.1A2.7,2.7,0,0,0,139.7,3.7Z M119.9,4.2h-2.6v-2h2.6a1.1,1.1,0,0,1,1.2,1A1.1,1.1,0,0,1,119.9,4.2Zm3.7-1.1A3.1,3.1,0,0,0,120.2,0h-5.4V9.7h2.5V6.3h.9l2.9,3.4h3.1l-3.1-3.5A3,3,0,0,0,123.6,3.1Z M124.9,9.7h8V7.5h-5.4V6h5.3V3.7h-5.3V2.2h5.4V0h-8Z M110,4.3h-2.7V2.2H110a1.1,1.1,0,0,1,1.2,1.1C111.2,3.9,110.8,4.3,110,4.3Zm.3-4.3h-5.5V9.7h2.5V6.5h2.9c2.2,0,3.5-1.3,3.5-3.2A3.1,3.1,0,0,0,110.3,0Z M149,3.7h-2.4a.7.7,0,0,1-.8-.7.7.7,0,0,1,.8-.8h4.2l1-2.2h-5.2a3,3,0,0,0-3.3,3.1,2.7,2.7,0,0,0,3,2.9h2.3a.8.8,0,0,1,.9.7.8.8,0,0,1-.9.8h-5V9.7h5A3.1,3.1,0,0,0,152,6.6,2.7,2.7,0,0,0,149,3.7Z M84.5,9.7h8V7.5H87V6h5.3V3.7H87V2.2h5.5V0h-8Z M104.3,0h-3.2L98.7,2.9,96.2,0H92.9l4.2,4.8L92.8,9.7H96l2.6-3.1,2.6,3.1h3.3l-4.3-5Z"/>
</svg>

);

export default AmexPlatinum;
