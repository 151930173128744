/** @format */
import React from 'react';

class Explore extends React.Component {	
	render() {
		return (
			<div id="sectionTypography" style={{padding: "140px 35px 0"}}>
				<h1>Svenna Birch Tweed</h1>
				<h2>by Jessica Bellamhey</h2>
				<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Amet sed libero id elementum molestie nec odio. Congue elit ut vel volutpat diam orci. Tempus facilisis justo turpis hac sed risus lacus feugiat porttitor. Velit, velit suspendisse massa, amet tellus neque scelerisque tincidunt lobortis.</p>
				<div className="infoTable">
					<div>
						<h4>Year</h4>
						<p className="small">2021</p>
					</div>
					<div>
						<h4>Medium</h4>
						<p className="small">Acrylic</p>
					</div>
					<div>
						<h4>Size</h4>
						<p className="small">20x20x40cm</p>
					</div>
				</div>
				<h3>I believe that Lorem artwork is not ipsum merely science dolar and technology, far but also a state of of the new art.</h3>
			</div>
		);
	}
}

export default Explore;
