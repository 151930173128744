/** @format */
import React from 'react';
import ArtTitle from "./ArtTitle";
import ExploreTheSpace from "../ExploreTheSpace";
import Item from '../Item';

class ArtworkItem extends React.Component {
	state = {
		overlay: false
	};

	hidePurchaseOverlay = () => {
		const body = document.querySelector('body');
		body.classList.remove('locked');
		this.setState({ overlay: false });
	}

	showPurchaseOverlay = () => {
		const body = document.querySelector('body');
		body.classList.add('locked');
		this.setState({ overlay: true });
	}
	
	render() {
		const data = this.props.pagedata[0];
		if (data === undefined) return(null);

		const showOverlay = this.state.overlay ? "show" : "";

		return (
			<div className="artItemPageBg">
				<div className={`purchaseOverlay ${showOverlay}`}>
					<svg className="closeSVG" viewBox="0 0 50 50" onClick={ this.hidePurchaseOverlay }>
						<path d="M18,18 L32,32"></path>
						<path d="M32,18 L18,32"></path>
						<circle cx="25" cy="25" r="16"></circle>
					</svg>
					<p>By clicking “Continue,” you will be directed to an external non-American Express website to complete any transactions. </p>
					<div className="linkButton white" onClick={ this.hidePurchaseOverlay }>Don't Allow</div>
					<br />
					<a className="continueLink" target="_blank" rel="noopener noreferrer" href={data.buyNowLink}>
						<div className="linkButton white plink" onClick={ this.hidePurchaseOverlay }>Continue</div>
					</a>
				</div>
				<div className="artitem feature-image">
					<img src={data.artworkImage} alt={ `The artwork ${data.title}` }/>
				</div>
				<div className={`sectionArtwork ${data.overlaps}`}>
					<ArtTitle text={ data.title } visible={ true }></ArtTitle>

					<h2>{data.artist}</h2>
					<p>{data.aboutArtwork}</p>
					<div className="infoTable">
						<div>
							<h4>Year</h4>
							<p className="small">{data.year}</p>
						</div>
						<div>
							<h4>Medium</h4>
							<p className="small">{data.medium}</p>
						</div>
					</div>
					<div className="infoTable">
						{ data.price && (<div>
							<h4>Price</h4>
							<p className="small">{data.price}</p>
						</div>)}

						<div>
							<h4>Size</h4>
							<p className="small pre">{data.dimensions}</p>
						</div>
						
					</div>
					
					{ !data.isArtCard && (<div className="linkButton" onClick={ this.showPurchaseOverlay }>Buy Now</div>)}
					{ data.isArtCard && (<div className="linkButton" ><a href="https://americanexpress.com/artbyplatinum/" target="_blank">Learn More</a></div>)}
				</div>
				
				<div className="sectionArtist">
					<h1>About</h1>
					<h1 className="title-two">the artist</h1>
					<p>{data.aboutArtist1}</p>
					<div className={`feature-image ${data.imgfloat}`}>
						<img src={data.artistImage} alt={`The artist ${data.artist}`} />
					</div>
					{ data.imgfloat !== 'none' && (<div className="clear"></div>)}
					<p>{data.aboutArtist2}</p>

				</div>
				<ExploreTheSpace></ExploreTheSpace>
			</div>
			)
		}
	}

export default ArtworkItem;
