const artworks =
[{
	"genericName": 1,
	"artworkImage": "/images/artworks/1.png",
	"overlaps":"overlaps",
	"artistImage": "/images/artists/Asif-Hoque.png",
	"imgfloat":"left",
	"title": "Messenger no. 2, 2021",
	"artist": "Asif Hoque",
	"year": "2021",
	"medium": "Painting",
	"dimensions": "19 x 24 inches\n (horizontal)",
	"price": "$6,000",
	"buyNowLink": "https://www.artsy.net/trove-1",
	"featured": false,
	"orderList": 12,
	"aboutArtwork": "Asif Hoque is know for his paintings of brown-bodied figures, often angelic and set within in sweeping clouds or grasses. This piece, done in his signature style on pale brown linen, incorporates his endearing celestial protagonists, which reflect Hoque's own multicultural identity: The artist was born in Rome to Bangladeshi parents, raised between Rome and South Florida, and is now based in New York. “I had three cultures in me by the time I was ten,” Hoque has said.",
	"aboutArtist1": "Asif Hoque\n B. 1991, Bangladeshi American.\n\nThe expressive figures of Asif Hoque’s allegorical paintings explore themes such as gender, identity, and the body. Hoque’s work is heavily influenced by his unique experience as a Bangladeshi immigrant raised between Rome and South Florida. As visual representations of the complexity of his multicultural identity,  ",
	"aboutArtist2": "Hoque’s paintings depict figures and mythical animals in nature. The reddish-brown complexions often contrast with the electric green plants and grasslands of their surroundings. Hoque’s figures are often nude and possess bulbous, almost puffy limbs and voluptuous bodies. In Untitled (2020), two swirling figures are shown in a field with mountains behind them. Above their heads, two small cupid-like characters hang suspended in the air, ready to shoot the couple with heart-shaped arrowheads. Hoque’s mystical works have been presented in solo exhibitions at galleries in Los Angeles and New York.",
	hotspot: {
		posX: 976,
		posY: 897,
		multi: 1
	},
},
{
	"genericName": 2,
	"artworkImage": "/images/artworks/2.png",
	"overlaps":"overlaps",
	"artistImage": "/images/artists/Bella-Foster.png",
	"imgfloat":"left",
	"title": "The Diggers",
	"artist": "Bella Foster",
	"year": "2021",
	"medium": "Painting",
	"dimensions": "40 x 30 inches",
	"price": "$6,500",
	"buyNowLink": "https://www.artsy.net/trove-2",
	"featured": true,
	"orderList": 1,
	"aboutArtwork": "This canvas by the Northern California–based Bella Foster is a prime example of her approach to the still life. Her fresh reinvigoration of the age-old genre includes vibrant washes of color and fluid forms that rethink the interior worlds we inhabit. Foster has often peppered her works with references to fellow artists and art historical greats, from David Hockney ot Matisse, as well as personal objects that belong to friends and peers. ",
	"aboutArtist1": "Bella Foster\n B. 1985, American.\n\n Bella Foster was born in Los Angeles, CA in 1985 and lives in Grass Valley, CA. She received a Bachelor of Fine Art from the School of Visual Arts in New York, NY in 1997. One person exhibitions of her work have been presented at The Pit, Glendale, CA [2018, 2020], Canada, New York, NY (2017), South Willard, Los Angeles, CA (2015), and Art Since the Summer of 69, New York, NY (2010),",
	"aboutArtist2": "and a two-person exhibition with Elwyn Palmerton was presented at Southfirst, Brooklyn, NY in 2008. Foster’s work has been featured in thematic exhibitions such as Palo Santo, Ratio 3, San Francisco, CA (2018); Fort Greene, Venus Over Los Angeles, Los Angeles, CA (2016); Material, Salon 94, New York, NY (2012); Greater Brooklyn, CRG Gallery, New York, NY (2005); and Where are we going? Where do we come from? And where are we going?, Champion Fine Art, Los Angeles, CA (2004).",
	hotspot: {
		posX: 976,
		posY: 897,
		multi: 1
	},
},
{
	"genericName": 3,
	"artworkImage": "/images/artworks/3.png",
	"overlaps":"overlaps",
	"artistImage": "/images/artists/Camilla-Engstrom.png",
	"imgfloat":"left",
	"title": "TBC",
	"artist": "Camilla Engström",
	"year": "2021",
	"medium": "Painting",
	"dimensions": "32 x 26 inches",
	"price": "$TBD",
	"buyNowLink": "https://www.artsy.net/trove-3",
	"featured": false,
	"orderList": 15,
	"aboutArtwork": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Amet sed libero id elementum molestie nec odio. Congue elit ut vel volutpat diam orci. Tempus facilisis justo turpis hac sed risus lacus feugiat porttitor. Velit, velit suspendisse massa, amet tellus neque scelerisque tincidunt lobortis.",
	"aboutArtist1": "Camilla Engström \n B. 1989, Swedish. \n\n  In vibrant, sorbet-colored landscapes, the self-taught painter Camilla Engström creates paeans to ecological empowerment and rebels against female beauty standards. Her bright scenes often feature Husa, a pink, playful, full-bodied character that Engström developed as an alter ego. The artist views Husa as a rejection of the tall, thin, otherworldly models that she had to sketch when making fashion illustrations.  ",
	"aboutArtist2": "Engström has even created an Instagram page devoted to the character. Sometimes, the land and female forms combine in Engström’s compositions, evoking the canvases of Georgia O’Keeffe, whom the younger artist counts as her number-one inspiration. Engström has exhibited in New York, Los Angeles, Stockholm, and Hong Kong. In 2016, she published A Book of Dicks, filled with irreverent illustrations.",
	hotspot: {
		posX: 976,
		posY: 897,
		multi: 1
	},
},
{
	"genericName": 4,
	"artworkImage": "/images/artworks/4.png",
	"overlaps":"overlaps",
	"artistImage": "/images/artists/Giorgio-Celin.png",
	"imgfloat":"left",
	"title": "Quienes somos? dónde vamos?",
	"artist": "Giorgio Celin",
	"year": "2021",
	"medium": "Painting",
	"dimensions": "47 x 41 inches",
	"price": "$7,000",
	"buyNowLink": "https://www.artsy.net/trove-4",
	"featured": false,
	"orderList": 6,
	"aboutArtwork": "This work is part of Giorgio Celin's \"Pajaros del Atlantico\" series, an ongoing body of work that explores themes of Latinx immigration, self-discovery, queerness, gender, human relationships and acceptance. According to the artist: \"The brown, mestizo bodies experience joy, sadness, pain and solitude, reclaiming space in the visual conversation. Existing outside the monolithic perception of the mainstream Latinx stereotype. In this latest part of the series, I choose to paint the city  at night, as an additional character in the scene. I choose to portray the places of everyday life where an immigrant is supposed to move—train stations, fast food restaurants, the night bus, the metro station, etc.—challenging the idea of the 'elegant interiors'  and the phony, high class aesthetic that is often associated with portraits, or figurative painting.\"",
	"aboutArtist1": "Giorgio Celin \n B. 1986, Colombian. \n\n Giorgio Celin is known for his dazzling figurative paintings which explore themes including migration, belonging, relationships, and nostalgia. Celin’s work is influenced by his experience as a Colombian migrant who has lived in several European cities. Through his paintings, he examines issues surrounding displacement and what it means to feel as though you don’t belong in any one geographical location.",
	"aboutArtist2": "Celin’s work also aims to represent queer bodies in a non-objectified way, and frequently depicts diverse couples sharing intimate moments. He employs vivid shades of purple, red, and blue to create his figures, which are characterized by bold contours, precise brushstrokes, and curvilinear limbs. Dream A Little Dream Of Me (2020), for instance, shows two partially nude figures laying on the floor, wrapped in a loving embrace. Celin has exhibited his work at galleries and institutions around the world including the Museum of Contemporary Art of Rome.",
	hotspot: {
		posX: 545,
		posY: 900,
		multi: 2
	},
},
{
	"genericName": 5,
	"artworkImage": "/images/artworks/5.png",
	"overlaps":"overlaps",
	"artistImage": "/images/artists/Henry-Glavin.png",
	"imgfloat":"left",
	"title": "The Piano and the Dog Gate",
	"artist": "Henry Glavin",
	"year": "2021",
	"medium": "Painting",
	"dimensions": "60 x 48 inches",
	"price": "$12,000",
	"buyNowLink": "https://www.artsy.net/trove-5",
	"featured": false,
	"orderList": 7,
	"aboutArtwork": "This large painting by the rising young American painter Henry Glavin is a prime example of the artist's defly rendered interiors that are devoid of humans yet rife with intrigue. This piece, with its two landscape paintings, evidences the artist's intermingling of architecture and the outdoors, as well as his keen ability to leave us wondering what lingers beyond the frame. Each of Glavin's works is paintstakling realized through a process of drawing, painting, sanding, and ink transfer.",
	"aboutArtist1": "Henry Glavin \n B. 1991, American. \n\n Henry Glavin was born in New York, NY in 1991. He earned his BFA in Painting and Ceramics from Alfred University where he was granted the Outstanding BFA Thesis Award, The Daniel Joseph Murphy II Memorial Award, and the Fred H. Wertz Award for writin.",
	"aboutArtist2": "Glavin was awarded a St. Botolph Club Foundation Emerging Artist Award and Vermont Studio Center residency. He was included in the exhibition In My Room: Artists Paint the Interior 1950-Now at The Fralin Museum of Art, UVA, Charlottesville, VA. He lives and works in Brooklyn, NY. Never Paint A Ladder marks Glavin’s second solo show with Halsey McKay Gallery.",
	hotspot: {
		posX: 545,
		posY: 900,
		multi: 2
	},
},
{
	"genericName": 6,
	"artworkImage": "/images/artworks/6.png",
	"overlaps":"overlaps",
	"artistImage": "/images/artists/JessicaTaylor-Bellamy.png",
	"imgfloat":"left",
	"title": "Coping Mechanism II (Bouquet)",
	"artist": "Jessica Bellamy",
	"year": "2021",
	"medium": "Painting",
	"dimensions": "36 x 24 inches",
	"price": "$4,400",
	"buyNowLink": "https://www.artsy.net/trove-6",
	"featured": true,
	"orderList": 3,
	"aboutArtwork": "On one end, California is mythologized as a place where national parks and liberal ideology pervade. On the other, it is the subject of climate, agricultural, and social grief, which must be resolved before the passage of time proves to be too late. To communicate these realities, Bellamy expanded her horizons from oil painting to an image-transfer technique that incorporates the fire-followers and images from the Los Angeles Times, using them to trace the silhouette of various scenes and objects known to the L.A. zeitgeist. The deterioration in the process of transferring the images from the material gives a feeling of nostalgia and missing pieces of information. As such, it stands as a metaphor for seeing the two sides, including the one that is not advertised, which must be confronted and cannot be modified. \"I myself am struggling with systems that lead to degradation of the environment and social construct and think a lot about our dependence on each other even while often apart in Los Angeles,\" Bellamy said. \"I’m still trying to find beauty in certain symbols, isolating smokey sunsets, and combining them with cautious optimism for the still existent California dream, with a genuine fondness for everyday aspects of a place.\"",
	"aboutArtist1": "Jessica Bellamy\n B. 1992, Cuban American. \n\n Jessica Bellamy was born in Whittier, CA and lives & works in Los Angeles, CA. She received a B.A. in Political Science from USC in 2014 and is an MFA candidate at USC Roski School of Fine Arts. Bellamy’s interest in the environment, years spent working at KCRW, and personal and familial histories as a woman of Afro-Cuban and Jewish descent greatly influence the themes and world-making in her work. ",
	"aboutArtist2": "Predominantly an oil painter, Bellamy also works with image transfer and assemblage, using the familiarity of the Southern California landscape and aesthetics to connect shared visual references, presenting compositions that are dream-like narratives giving form to unseen or alternative possibilities. Her work has been included in thematic exhibitions such as Boiling Point (2019) and Parallel Realities and Unpopular Truths (2020) with Superposition Gallery. She is an active member of The Circuit, a Black Arts Coalition, focusing on academic and art market reform.",
	hotspot: {
		posX: 545,
		posY: 900,
		multi: 2
	},
},
{
	"genericName": 7,
	"artworkImage": "/images/artworks/7.png",
	"overlaps":"overlaps",
	"artistImage": "/images/artists/Jibade-Khalil-Huffman.png",
	"imgfloat":"left",
	"title": "TRT",
	"artist": "Jibade-Khalil Huffman",
	"year": "2020",
	"medium": "Photo Collage",
	"dimensions": "36.78 x 38.62 inches",
	"price": "$7,000",
	"buyNowLink": "https://www.artsy.net/trove-7",
	"featured": false,
	"orderList": 8,
	"aboutArtwork": "Centered around a news photograph of tennis superstars and sisters, Venus and Serena Williams, TRT, is concerned with various kinds of watching imposed on the Black body by the state, the media and society. The work can be read as a critique of surveillance, in the form of press or in the more egregious form of state surveillance often experienced by prominent Black thinkers, organizers and celebrities. The embrace of the Williams sisters however, counters and acts as a stand in for our potential for collectivity in the face of various institutional infringements.",
	"aboutArtist1": "Jibade-Khalil Huffman\n B. 1981, American. \n\n Jibade-Khalil Huffman (b. 1981, Detroit, MI) is an artist and writer who uses found, archival material, and contemporary ephemera to address slippage in memory and language, particular to race and visibility. Often working site-specifically, his work takes the form of installation, video, projections, photographic light boxes, and photo collages printed on layered transparencies and paper.",
	"aboutArtist2": "Foregrounding the materiality of digital media and its degradation over time, Huffman’s approach dissolves explicit meaning in order to reconstitute it as objects in perpetual flux. Through projection and repetition, his work evokes the untranslatable, ruminating on the liminal qualities of singular experiences through the flattening of symbolic and semiotic hierarchies.",
	hotspot: {
		posX: 545,
		posY: 900,
		multi: 2
	},
},
{
	"genericName": 8,
	"artworkImage": "/images/artworks/8.png",
	"overlaps":"overlaps",
	"artistImage": "/images/artists/Johnson-Eziefula.png",
	"imgfloat":"left",
	"title": "Just as green in Atlanta",
	"artist": "Johnson Eziefula",
	"year": "2021",
	"medium": "Painting",
	"dimensions": "60 x 48 inches",
	"price": "$25,000",
	"buyNowLink": "https://www.artsy.net/trove-9",
	"featured": false,
	"orderList": 16,
	"aboutArtwork": "This work by Johnson Eziefula is typical of the young Nigerian artist's mixed media painting practice: He combines acrylics, charcoal, pastels, and textiles to create dazzling portrait of friends and loved ones. Through featuring his subjects with choice sartorial statements, Eziefula has been known to comment on cultural hybridity, migration, and Westernization.",
	"aboutArtist1": "Johnson Eziefula\n B. 1988, Nigerian. \n\n Johnson Eziefula’s work depicts young Black people as visual icons mimicking physical hybrids, expressing a distinctive sense of westernized contemporary style, social behaviour, oozing aura & manifesting individual personalities as well as identity, perhaps being symbolized as a visual demonstration of the inevitable result of globalization, migration, post-colonization and the presence of technological advancements on the physical representation of young Black people, culturally. ",
	"aboutArtist2": "The artist addresses the inevitable hybridity of self & cultural influence, urging that this ‘Newness of Self’ with all the questions it brings, be celebrated. Eziefula’s work has been exhibited globally. Exhibitions include, A friendly Exchange, Ross-Sutton Gallery, New York (2021); Stop, Listen!, CFHILL, Stockholm (2021); BLACK VOICES: Friend of My Mind, Ross-Sutton Gallery, New York (2020); Liminality in Infinite Space, African Artists’ Foundation and Alliance Française, Lagos (2020) and Cultural Hybrids, Corridor Contemporary Art Gallery, Tel-Aviv (2020).",
	hotspot: {
		posX: 976,
		posY: 897,
		multi: 1
	},
},{
	"genericName": 9,
	"artworkImage": "/images/artworks/9.png",
	"overlaps":"overlaps",
	"artistImage": "/images/artists/Nadia-Ayari.png",
	"imgfloat":"left",
	"title": "Fold 1",
	"artist": "Nadia Ayari",
	"year": "2018",
	"medium": "Painting",
	"dimensions": "30 x 30 inches",
	"price": "$12,000",
	"buyNowLink": "https://www.artsy.net/trove-10",
	"featured": false,
	"orderList": 9,
	"aboutArtwork": "In this body of work, Nadia Ayari endowed lush, elegant leaves and branches with human emotion. This piece, with its supple brown branches and crisp blue-green leaves, exemplies her ability to infuse these natural forms with notions of survival and desire.",
	"aboutArtist1": "Nadia Ayari\n B. 1981, Tunisian American. \n\n Nadia Ayari had an important realization at the beginning of her career as an artist: “I realized I didn’t actually have to focus on being a political painter; being a painter is political enough,” she told Vogue. Inspired by the work of painters like Philip Guston and Leon Golub, whom she studied while earning her MFA from the Rhode Island School of Design, Ayari’s multidisciplinary work spans medium, subject, and technique to explore the intersection of the personal and the political. ",
	"aboutArtist2": "From bright frescoes painted on blocks of marble to luscious depictions of fig-tree branches interrupted with violent stripes of red, Ayari’s work is texturally rich and symbolically charged. In addition to exhibiting her work in solo shows internationally, Ayari has had residencies at the Skowhegan School of Painting and Sculpture, the Fine Arts Work Center, and A.i.R Dubai.",
	hotspot: {
		posX: 976,
		posY: 897,
		multi: 1
	},
},{
	"genericName": 10,
	"artworkImage": "/images/artworks/10.png",
	"overlaps":"overlaps",
	"artistImage": "/images/artists/Timothy-Lai.png",
	"imgfloat":"left",
	"title": "Two Loses",
	"artist": "Timothy Lai",
	"year": "2021",
	"medium": "Painting",
	"dimensions": "24 x 18 inches",
	"price": "$6,500",
	"buyNowLink": "https://www.artsy.net/trove-11",
	"featured": false,
	"orderList": 17,
	"aboutArtwork": "In this work, Timothy Lai reflects on a funeral he attended via Zoom earlier this year. The tender, contemplative work is typical of the young artist's practice; he often infuses his works with hints of autobigraphy.",
	"aboutArtist1": "Timothy Lai\n B. 1987, Kota Bharu, Malaysia. \nLives and works in Providence, Rhode Island. \n\n Timothy Lai (b. 1987, Kota Bharu, Malaysia) lives and works in Providence, Rhode Island. He received his BA in communication from the University of Texas at San Antonio in 2009, and his MFA in painting from the Rhode Island School of Design in 2017. ",
	"aboutArtist2": "His work has been featured in group presentations at Jack Barrett, Essex Flowers, Bass & Reiner Gallery, Good Naked Gallery, and Field Projects. His first post graduate solo exhibition was in 2019 at 00-LA Gallery. He had a solo exhibition with Jack Barrett in fall 2021.",
	hotspot: {
		posX: 545,
		posY: 900,
		multi: 2
	},
},
{
	"genericName": 11,
	"artworkImage": "/images/artworks/11.png",
	"overlaps":"overlaps",
	"artistImage": "/images/artists/jiha-moon.png",
	"imgfloat":"left",
	"title": "Afina",
	"artist": "Jiha Moon",
	"year": "2018",
	"medium": "Ceramic",
	"dimensions": "12 x 8 x 7 inches",
	"price": "$2,500",
	"buyNowLink": "https://www.artsy.net/trove-8",
	"featured": false,
	"orderList": 19,
	"aboutArtwork": "This ceramic sculpture by the Korean-born, Atlanta-based artist Jiha Moon is typical of the artist's playful approach to merging Eastern and Western art traditions and popular culture. This piece, with it's emoji-like grin, deep turqoise glaze, and locks of braided hair, evidences the artist's keen ability to wittily address cultural colonization and assimilation.",
	"aboutArtist1": "Jiha Moon\n B. 1973, Korean. \n\n In her image- and color-saturated paintings and prints, Jiha Moon mashes up materials, motifs, and techniques to create dreamlike compositions, stuffed with Eastern and Western art historical and pop cultural references that challenge fixed notions of cultural identity and represent our information-overloaded world. Everything is fair game for Moon—she draws from sources high and low, real and virtual, ancient and contemporary, including 13th-century Taoist painting, American Pop, Disney cartoons, Dr. Seuss books, emoticons, fortune cookies, and Asian restaurant menus. ",
	"aboutArtist2": "Her exuberant compositions stretch and burst across the picture plane. Gestural, abstract passages and calligraphic brushstrokes flow into stylized landscapes, in which viewers may find Snow White consorting with an idealized Chinese female figure, while smiley-faced emoticons and ripe peaches float about them. At once playful and dark, Moon’s work sends-up the persistence of cultural stereotypes in our globalized world.",
	hotspot: {
		posX: 545,
		posY: 900,
		multi: 2
	},
},
{
	"genericName": 12,
	"artworkImage": "/images/artworks/12.png",
	"overlaps":"overlaps",
	"artistImage": "/images/artists/Olivia-Cognett.png",
	"imgfloat":"left",
	"title": "Cloud Vase",
	"artist": "Olivia Cognet",
	"year": "2021",
	"medium": "Ceramic",
	"dimensions": "14 x 10 x 8.5 inches",
	"price": "$1,800",
	"buyNowLink": "https://surfacemag.com/design-dose/product/olivia-cognet-cloud-vase",
	"featured": false,
	"orderList": 18,
	"aboutArtwork": "A one-off ceramic piece hand-fashioned in black stoneware clay and crackled white glaze.",
	"aboutArtist1": "An accomplished accessories designer for the likes of Lanvin, Sonia Rykiel, and Isabel Marant, Olivia Cognet recently expanded her purview to ceramic art. ",
	"aboutArtist2": "Whether working in clay or couture, she uses her hands to fashion one-of-a-kind pieces that incorporate a sensual mix of, in her own words, “modern Brutalism and absolute femininity.”",
	hotspot: {
		posX: 534,
		posY: 652,
		multi: false
	},
},
{
	"genericName": 13,
	"artworkImage": "/images/artworks/13.png",
	"overlaps":"overlaps",
	"artistImage": "/images/artists/Thomas-Barger.png",
	"imgfloat":"left",
	"title": "Claws",
	"artist": "Thomas Barger",
	"year": "2021",
	"medium": "Mixed Media",
	"dimensions": "13 x 9 inches",
	"price": "$4,000",
	"buyNowLink": "https://surfacemag.com/design-dose/product/thomas-barger-chrome",
	"featured": false,
	"orderList": 13,
	"aboutArtwork": "A memorable objet, made of paper and sawdust, that evokes a metallic star doing aerobics.",
	"aboutArtist1": "Thomas Barger uses furniture to ruminate on his childhood—particularly chunky, throne-like chairs modeled on the sand formations near his rural Illinois hometown.",
	"aboutArtist2": "Lately, the Brooklyn artist has been looking for beauty in humble materials, a result of early stints working in the studios of detritus devotees Misha Kahn and Jessi Reaves, who encouraged him to push trash to inconceivable ends. With their unconventional forms, variegated colors, and protruding limbs, Barger’s rough-hewn sculptures suggest peculiar chairs and tables.",
	hotspot: {
		posX: 962,
		posY: 616,
		multi: false
	},
},
{
	"genericName": 14,
	"artworkImage": "/images/artworks/14.png",
	"overlaps":"overlaps",
	"artistImage": "/images/artists/Brecht-Gander.png",
	"imgfloat":"left",
	"title": "Mini Yo Burri!",
	"artist": "Brecht Wright Gander",
	"year": "2021",
	"medium": "Table Lamp Material list: Copper, Brass, Acrylic, Pigment, Fiberglass, Resin, LEDs",
	"dimensions": "20 x 6 x 8 inches",
	"price": "$6,000",
	"buyNowLink": "https://www.artsy.net/trove-14",
	"featured": false,
	"orderList": 11,
	"aboutArtwork": "Honoring Arte Povera pioneer Alberto Burri, it resembles a geode–or a glamorous wormhole.",
	"aboutArtist1": "Brecht Gander may only be a few years into his formal studio practice, but the Rhode Island native has already amassed an impressive portfolio of limitlessly innovative objects imbued with personality, curiosity, and chaos.",
	"aboutArtist2": "In an industry that tends to play by the rules, his work is unbound to any sort of convention—it’s created with the sole purpose of making you feel something different.",
	hotspot: {
		posX: 1320,
		posY: 622,
		multi: false
	},
},
{
	"genericName": 15,
	"artworkImage": "/images/artworks/15.png",
	"overlaps":"overlaps",
	"artistImage": "/images/artists/Caroline-Zimbalist.png",
	"imgfloat":"left",
	"title": "Peach",
	"artist": "Caroline Zimbalist",
	"year": "2021",
	"medium": "Decor Object",
	"dimensions": "9 x 3 inches",
	"price": "$550",
	"buyNowLink": "https://surfacemag.com/design-dose/product/caroline-zimbalist-peach",
	"featured": false,
	"orderList": 20,
	"aboutArtwork": "Made using biomaterials, this fiery vessel nods to the future and appears frozen in time.",
	"aboutArtist1": "With nontraditional techniques at the intersection of fashion, science, and engineering, Caroline Zimbalist transforms raw materials into ethereal objects such as tabletop planters and sartorial accoutrements that conjure the appearance of textiles frozen in time. ",
	"aboutArtist2": "Each one-of-a-kind piece is inflected with biomimicry to emphasize nature’s dominance, riffing on how humans have irreversibly altered the world while forecasting those implications on the future.",
	hotspot: {
		posX: 532,
		posY: 540,
		multi: false
	},
},
{
	"genericName": 16,
	"artworkImage": "/images/artworks/16.png",
	"overlaps":"overlaps",
	"artistImage": "/images/artists/Nina-Cho.png",
	"imgfloat":"left",
	"title": "Layering Transparency",
	"artist": "Nina Cho",
	"year": "2021",
	"medium": "Materials: Blown Glass, Translucent Grey Top, Translucent Blue Base",
	"dimensions": "19 x 19 x 9.5 inches",
	"price": "$4,200",
	"buyNowLink": "https://surfacemag.com/design-dose/product/nina-cho-layering-transparency",
	"featured": false,
	"orderList": 14,
	"aboutArtwork": "Handmade glass statement pieces that can be served as accent tables or tabletop objects.",
	"aboutArtist1": "Nina Cho upholds the “aesthetic of emptiness,” a time-honored Korean tradition that values positive and negative space in equal measure. ",
	"aboutArtist2": "By eliminating the extraneous and distilling forms down to their most essential elements, the Seoul-raised, Detroit-based designer crafts cutting-edge objects whose distinct colors, shapes, and materials harmoniously interact with the spirits of their surroundings.",
	hotspot: {
		posX: 1252,
		posY: 850,
		multi: false
	},
},
{
	"genericName": 17,
	"artworkImage": "/images/artworks/17.png",
	"overlaps":"overlaps",
	"artistImage": "/images/artists/Leah-Ring.png",
	"imgfloat":"left",
	"title": "Nymphaea Lamp",
	"artist": "Leah Ring",
	"year": "2021",
	"medium": "Acrylic",
	"dimensions": "26 x 13 x 60 inches",
	"price": "$6,800",
	"buyNowLink": "https://surfacemag.com/design-dose/product/leah-ring-nymphaea-lamp",
	"featured": false,
	"orderList": 21,
	"aboutArtwork": "When viewed from above, it resembles magical water lilies glowing in cotton-candy hues.",
	"aboutArtist1": "Leah Ring is tapping into everyone’s inner child. ",
	"aboutArtist2": "The sculptural objects she creates through Another Human, her Los Angeles–based multidisciplinary design studio, use playful geometry and unconventional materials to realize her vision of an idyllic, transcendent consciousness—one where weird is the norm and imaginations are free to roam without roadblocks.",
	hotspot: {
		posX: 1045,
		posY: 526,
		multi: false
	},
},
{
	"genericName": 18,
	"artworkImage": "/images/artworks/18.png",
	"overlaps":"overlaps",
	"artistImage": "/images/artists/Myles-Nurse.png",
	"imgfloat":"left",
	"title": "Stack Cats",
	"artist": "Myles Nurse",
	"year": "2021",
	"medium": "Sprayed steel",
	"dimensions": "34.5 x 20 x 21.5 inches",
	"price": "$1,200",
	"buyNowLink": "https://surfacemag.com/design-dose/product/myles-nurse-stack-cats-chair",
	"featured": false,
	"orderList": 10,
	"aboutArtwork": "Each individual bright red steel rod supports the other in this skeletal statement piece.",
	"aboutArtist1": "With metal rods as his material of choice, the Brooklyn-born Myles Nurse hand-fashions sculptural furniture that reflects his ultra-pragmatic design approach.",
	"aboutArtist2": "Whether a side table, fruit bowl, or coffee table, an intricate system of rods connect in web-like formations, illustrating how each individual part is necessary to support the entire whole. ",
	hotspot: {
		posX: 818,
		posY: 643,
		multi: false
	},
},
{
	"genericName": 19,
	"artworkImage": "/images/artworks/19.png",
	"overlaps":"overlaps",
	"artistImage": "/images/artists/Kouros-Maghsoudi.png",
	"imgfloat":"left",
	"title": "Behsheen Chair",
	"artist": "Kouros Maghsoudi",
	"year": "2021",
	"medium": "3D-printed corn-based plastic with an up-cycled metal armature and FSC®-certified MDF. All energy consumption and greenhouse gases emitted during fabrication neutralized with carbon off-setting.",
	"dimensions": "32 x 18 x 20 inches \nseat height 18 inches",
	"price": "$9,300",
	"buyNowLink": "https://surfacemag.com/design-dose/product/kouros-maghsoudi-behsheen-chair",
	"featured": false,
	"orderList": 4,
	"aboutArtwork": "Playful, loud, and with touches of kitsch, this chair brings out your hedonistic side.",
	"aboutArtist1": "Design has always surrounded Kouros Maghsoudi, who was born in the Frank Lloyd Wright suburbs of Chicago to parents who owned a boutique women’s clothing store and Persian rug galleries.",
	"aboutArtist2": "After earning his degree in sustainable architecture and urban design and joining an environmental nonprofit, he recently debuted his own furniture collection, called Mehmooni, that draws directly from his Persian culture but tinged with playful postmodern motifs that encourage people to explore their hedonistic side.",
	hotspot: {
		posX: 626,
		posY: 589,
		multi: false
	},
},
{
	"genericName": 20,
	"artworkImage": "/images/artworks/20.png",
	"overlaps":"overlaps",
	"artistImage": "/images/artists/Ryan-Belli.png",
	"imgfloat":"left",
	"title": "Purple Chair",
	"artist": "Ryan Belli",
	"year": "2021",
	"medium": "Carved wood",
	"dimensions": "30 x 22 x 22 inches",
	"price": "$3,000",
	"buyNowLink": "https://surfacemag.com/design-dose/product/ryan-belli-purple-chair",
	"featured": false,
	"orderList": 22,
	"aboutArtwork": "It might look spray-painted, but this cartoonishly carved wood chair is naturally purple.",
	"aboutArtist1": "Ryan Belli started interning for the Haas Brothers while studying product design at the ArtCenter College of Design in Pasadena.",
	"aboutArtist2": "The Angeleno designer's output reflects his Haasian influences—whimsical, anthropomorphic creations that conjure a fantastical realm—yet abounds with references to design greats.",
	hotspot: {
		posX: 1138,
		posY: 703,
		multi: false
	},
},
{
	"genericName": 21,
	"artworkImage": "/images/artworks/21.png",
	"overlaps":"overlaps",
	"artistImage": "/images/artists/Ara-Thorose.png",
	"imgfloat":"left",
	"title": "ULU Chair",
	"artist": "Ara Thorose",
	"year": "2020",
	"medium": "Fabric, foam, steel",
	"dimensions": "Double Seat\n 24x 36 x 24 inches",
	"price": "$10,000",
	"buyNowLink": "https://surfacemag.com/design-dose/product/ara-thorose-ulu-chair",
	"featured": true,
	"orderList": 2,
	"aboutArtwork": "Inspired by circles trapped in squares, this plush piece speaks to the neotenic movement.",
	"aboutArtist1": "Not one to shy away from spectacle, Ara Thorose has mastered a uniquely choreographic approach to making furniture fun again.",
	"aboutArtist2": "His sculptural chairs—kinetic shapes best described as “play as research” under his brand, Soft Limits—are at once refreshingly playful and deliriously inventive.  ",
	hotspot: {
		posX: 511,
		posY: 298,
		multi: false
	},
},
{
	"genericName": 22,
	"artworkImage": "/images/artworks/22.png",
	"overlaps":"overlaps",
	"artistImage": "/images/artists/Christina-Antonio.png",
	"imgfloat":"left",
	"title": "Helios Chair",
	"artist": "Christina Z Antonio",
	"year": "2021",
	"medium": "Blown Glass with Neon, hand stitched leather seat. Layered leather, with satin brass feet.",
	"dimensions": "34 x 18 x 18 inches",
	"price": "$18,000",
	"buyNowLink": "https://surfacemag.com/design-dose/product/christina-z-antonio-helios-chair",
	"featured": false,
	"orderList": 5,
	"aboutArtwork": "Luminous neon arches invest Helios with the futurist idea of a \"human charging station.\"",
	"aboutArtist1": "A master craftsperson in multiple mediums, Christina Z Antonio pushes material boundaries to their extremes by incorporating disparate elements into the furniture, lighting, and hardware she creates from her studio in Flatiron, New York. ",
	"aboutArtist2": "Her latest lighting fixtures manipulate color by creating misty bioluminescent fades, dialing into neuroaesthetics to forge unique sensory experiences that prioritize harmony, balance, and soothing the nerves.",
	hotspot: {
		posX: 1253,
		posY: 396,
		multi: false
	},
},
{
	"genericName": 23,
	"artworkImage": "/images/artworks/23.png",
	"overlaps":"overlaps",
	"artistImage": "/images/artists/Kehinde-Wiley.png",
	"imgfloat":"left",
	"title": "Platinum x Kehinde Wiley ",
	"artist": "Kehinde Wiley",
	"year": "2021",
	"medium": "Metal Card",
	"dimensions": "3 x 2 inches",
	"price": false,
	"buyNowLink": "https://some.link?artwork=23",
	"featured": false,
    "isArtCard": true,
	"orderList": 23,
	"aboutArtwork": "Make your Platinum Card® a work of art. Choose one of our new Art x Platinum designs, exclusively for Platinum Card Members. Available January 20, 2022",
	"aboutArtist1": "Kehinde Wiley is a visionary portrait painter, known for his distinct, vibrant botanical backgrounds. ",
	"aboutArtist2": "Platinum x Kehinde Wiley brings his botanical brilliance to the Platinum Card® so you can enjoy a bit of artistic inspiration every day.",
	hotspot: {
		posX: 766,
		posY: 865,
		multi: false
	},
},
{
	"genericName": 24,
	"artworkImage": "/images/artworks/24.png",
	"overlaps":"overlaps",
	"artistImage": "/images/artists/Julie-Mehretu.png",
	"imgfloat":"left",
	"title": "Platinum x \nJulie Mehretu",
	"artist": "Julie Mehretu",
	"year": "2021",
	"medium": "Metal Card",
	"dimensions": "3 x 2 inches",
	"price": false,
	"buyNowLink": "https://some.link?artwork=24",
	"featured": false,
    "isArtCard": true,
	"orderList": 24,
	"aboutArtwork": "Make your Platinum Card® a work of art. Choose one of our new Art x Platinum designs, exclusively for Platinum Card Members. Available January 20, 2022",
	"aboutArtist1": "Julie Mehretu is a renowned contemporary artist, linking architecture, history, and identity. ",
	"aboutArtist2": "Platinum x Julie Mehretu reimagines the Platinum Card® so you can enjoy a bit of artistic inspiration every day.",
	hotspot: {
		posX: 846,
		posY: 865,
		multi: false
	},
}]

export default artworks;