/** @format */

import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Preloader from './Preloader';
import Nav from './Nav';
import Home from './Home';
import Item from './Item';
import Scanner from './Scanner';
import Explore from './Explore'
import Map from './map/Map'
import Programming from './Programming'
import About from './About'
import Legal from './Legal';
import Terms from './Terms';
import Typography from './Typography';

export default class MainRouter extends React.Component {
	constructor(props) {
		super(props);
		const introSkipped = localStorage.getItem("skipIntro");
		this.state = { skipIntro: introSkipped, loadComplete: false };
	}

	onLoadComplete = () => {
		// <Home> plays intro animation when preloader fades away
		this.setState({ loadComplete: true });
	}

	render () {
		return (
			<Router>
				<React.Fragment>
					<Route path="" component={Nav} />
					<Switch>
						<Route exact path='/' render={(props) => <Home {...props} loadComplete={ this.state.loadComplete }></Home>} />
						<Route exact path='/item' component={Item} />
						<Route exact path='/artworks/:genericId' component={Item} />
						<Route exact path='/scanner' component={Scanner} />
						<Route exact path='/explore' component={Explore} />
						<Route exact path='/map' component={Map} />
						<Route exact path='/map/:mapLocation' component={Map} />
						<Route exact path='/programming' component={Programming} />
						<Route exact path='/art-x-platinum' component={About} />
						<Route exact path='/legal' component={Legal} />
						<Route exact path='/terms' component={Terms} />
						{/*TODO: Remove typography before production*/}
						<Route exact path='/type' component={Typography} />
					</Switch>
					<Preloader skipIntro={ this.state.skipIntro } loadComplete={ this.onLoadComplete }></Preloader>
					<div id="textureOverlay"></div>
				</React.Fragment>
			</Router>
		);
	}
}
