/** @format */
import React from 'react';
import artworks from '../artworks';
import ArtFull from './art/ArtFull';
import Footer from './Footer';
import gsap from "gsap";
import { ScrollToPlugin } from 'gsap/all';
gsap.registerPlugin(ScrollToPlugin); 

class Item extends React.Component {
	
	state = {
		artworks: [],
        pageData: [],
    }
	
      componentWillMount() {
		this.setState({ artworks: artworks });
	}
    
	componentDidMount() {
		window.scrollTo(0, 0);
        const { params } = this.props.match;
		this.setState({ artworks: artworks });

		const paramID = Number.parseInt(params.genericId);
		const found = this.state.artworks.find(element => element.genericName === paramID);
        this.setState ({pageData : [found]});
	}
    
	componentWillUnmount() {
	}

	 scrolltoAnchor = (element) => {
		gsap.to(window, 1, {scrollTo:{y:element.offsetTop-100, ease: 'in'}});
	  }
	
	scrollTo = (elem) => {
		const target = document.querySelector(elem);
		this.scrolltoAnchor(target)
	}

    render() {
    		return (
				<div>
                    <div>
                        <ArtFull pagedata={this.state.pageData}></ArtFull>
                    </div>
                    <Footer></Footer>
			    </div>
		    );
	    }
}

export default Item;