/** @format */
import React from 'react';
import ArtPreview from './ArtPreview';

class ArtPreviewCollection extends React.Component {

	componentDidMount() {
		window.scrollTo(0, 0);
	}
	// Sorts artworks by `orderList`
	getOrderedArtworks() {
		return this.props.artworks.sort((a, b) => {
			return a.orderList - b.orderList;
		});
	}

	// Only returns artworks with "featured: true"
	getFeaturedArtworks() {
		return this.getOrderedArtworks().filter((art) => art.featured);
	}

	render() {
		// Show featured only, or all
		const artList = (this.props.featuredOnly) ? this.getFeaturedArtworks() : this.getOrderedArtworks();

		return (
				<React.Fragment>
					{artList.map((detail, i) => 
						<ArtPreview details={detail} key={`art-${i}`}></ArtPreview>
					)}
				</React.Fragment>
			);
		}
	}

export default ArtPreviewCollection;
